import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputSwitch} from "primereact/inputswitch";
import axios from "axios";

const RegistrationPage = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const {t, i18n} = props;

  const validate = (data) => {
    let errors = {};
    if (!data.system_name) {
      errors.system_name = t('required_field');
    }
    if (!data.plain_password) {
      errors.plain_password = t('required_field');
      errors.password_check = t('required_field');
    }
    if (!data.email) {
      errors.email = t('required_field');
    }
    if (data.password_check && data.plain_password != data.password_check ) {
      errors.plain_password = t('passwords_not_match');
      errors.password_check = t('passwords_not_match');
    }
    if (!data.terms || data.terms !== true) {
      errors.terms = t('required_to_accept');
    }
    if (!data.privacy || data.privacy !== true) {
      errors.privacy = t('required_to_accept');
    }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "register",
      formData,
      {
        params: {},
        headers: {
          "Preferred-locale": localStorage.getItem('language')
        }
      })
      .then(response => {
        setLoading(false)
        window.App.toastShow('success', t('registration_success'))
        window.location.replace('/#/');
        window.App.forceUpdate()
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
      })

  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.getElementById('body').classList.add('outside')
    document.title = t('registration') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="RegistrationPage p-component">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel>
        <div className={"p-grid p-mb-4"}>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-col-align-center"}>
            <h3 className={"p-m-0"}>
              <i className={"pi pi-user"}></i> {t('registration')}
            </h3>
          </div>
        </div>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="system_name" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="system_name"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('instance_name')}</label>
                    <span className="p-d-block">
                      <InputText id="system_name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     system_name: e.target.value
                                   })
                                 }}
                                 value={formData.system_name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      <small className={"p-field-helper"}>
                          {t('instance_name_helper')}
                      </small>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="name" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="name"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                    <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="email" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="email"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('email')}</label>
                    <span className="p-d-block">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="phone" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="phone"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('phone')}</label>
                    <span className="p-d-block">
                      <InputText id="phone" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     phone: e.target.value
                                   })
                                 }}
                                 value={formData.phone}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="plain_password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="plain_password"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('password')}</label>
                    <Password id="plain_password"
                              tabIndex={3}
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.plain_password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  plain_password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="password_check" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password_check"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('password_check')}</label>
                    <Password id="password_check"
                              tabIndex={3}
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.password_check}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password_check: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-col-align-center"}>
                <Field name="terms" render={({input, meta}) => (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-2"}>
                        <div className="p-field p-fluid p-mb-0">
                          <InputSwitch
                            className={"p-mt-1"}
                            checked={formData.terms}
                            value={formData.terms}
                            onChange={e => setFormData({...formData, terms: e.target.value})}/>
                        </div>
                      </div>
                      <div className={"p-col-10 p-col-align-center"}>
                        <small className={"p-d-block"}>{t('accept_terms_and_conditions')}</small>
                        {getFormErrorMessage(meta)}
                      </div>
                    </div>
                  </>
                )}/>
              </div>
              <div className={"p-col-12 p-col-align-center"}>
                <Field name="privacy" render={({input, meta}) => (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-2"}>
                        <div className="p-field p-fluid p-mb-0">
                          <InputSwitch
                            className={"p-mt-1"}
                            checked={formData.privacy}
                            value={formData.privacy}
                            onChange={e => setFormData({...formData, privacy: e.target.value})}/>
                        </div>
                      </div>
                      <div className={"p-col-10 p-col-align-center"}>
                        <small className={"p-d-block"}>{t('accept_privacy_data')}</small>
                        {getFormErrorMessage(meta)}
                      </div>
                    </div>
                  </>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/"} className={"p-d-block"}>{t('login')}</Link>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit"
                          label={t('registration')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled={submitting}/>
              </div>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(RegistrationPage);
