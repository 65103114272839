import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_ChanelListComponent from "../components/_FM_chanel/_FM_ChanelListComponent";
import _FM_ChanelFormComponent from "../components/_FM_chanel/_FM_ChanelFormComponent";

const _FM_ChanelPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('reservation_chanel') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_ChanelPage p-component p-m-lg-4">
      <Panel>
        <_FM_ChanelListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarChanelForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarChanelForm: false,
               })}>
        <_FM_ChanelFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_ChanelPage);
