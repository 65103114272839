import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_ReservationListComponent from "../components/_FM_reservation/_FM_ReservationListComponent";
import _FM_ReservationFormComponent from "../components/_FM_reservation/_FM_ReservationFormComponent";

const _FM_AccomodatonPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('reservation') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
    setTimeout(() => {
      console.log(window.App.state)
    },1000)
  }, []);

  return (
    <div className="_FM_ReservationPage p-component p-m-lg-4">
      <Panel>
        <_FM_ReservationListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarReservationForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarReservationForm: false,
               })}>
        <_FM_ReservationFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_AccomodatonPage);
