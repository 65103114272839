import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_OoounitListComponent from "../components/_FM_ooounit/_FM_OoounitListComponent";
import _FM_OoounitFormComponent from "../components/_FM_ooounit/_FM_OoounitFormComponent";

const _FM_OoounitPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('oooooounit') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_OoounitPage p-component p-m-lg-4">
      <Panel>
        <_FM_OoounitListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOoounitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOoounitForm: false,
               })}>
        <_FM_OoounitFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_OoounitPage);
