import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import UserListComponent from "../components/user/UserListComponent";
import UserFormComponent from "../components/user/UserFormComponent";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

const UsersPage = (props) => {

  const {t} = props;

  useEffect(() => {
    document.title = t('users') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="UsersPage p-component p-m-lg-4">
      <Panel>
        <UserListComponent/>
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarUserForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarUserForm: false,
               })}>
        <UserFormComponent/>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(UsersPage);
