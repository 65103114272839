import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";

const _PC_RevolutsettingsShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsRevolutsettings && window.App.state.propsRevolutsettings.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "revolut-settings/" + window.App.state.propsRevolutsettings.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('revolutsettings_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarRevolutsettingsShow: false,
                        sidebarRevolutsettingsForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="merchant_api_private_key">{t('merchant_api_private_key')}</label>
              <span className="p-d-block p-text-bold">
                {formData.merchant_api_private_key}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="merchant_api_private_key">{t('merchant_api_readable_private_key')}</label>
              <span className="p-d-block p-text-bold">
                {formData.merchant_api_readable_private_key	}
              </span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_PC_RevolutsettingsShowComponent);
