import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";
import {BlockUI} from "primereact/blockui";
import {MultiSelect} from "primereact/multiselect";

import HelpComponent from "../HelpComponent";
import _NR_CategoryFormComponent from "../_NR_category/_NR_CategoryFormComponent";
import FormLocker from "../../util/FormLocker";

const _NR_MenucomboFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [selectedDishes, setSelectedDishes] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu-combo/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderMenucomboList: true,
            selectedMenucomboId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarMenucomboForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu-combo",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderMenucomboList: true,
            selectedMenucomboId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarMenucomboForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (window.App.state.propsMenucombo && window.App.state.propsMenucombo.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu-combo/" + window.App.state.propsMenucombo.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(localStorage.getItem('userId'))) {
            setLocking(true)
          }
          if(response.data && response.data.data && response.data.data.dishes && response.data.data.dishes[0]) {
            let _dishes = []
            response.data.data.dishes.map((dish, index) => {
              _dishes.push(dish.id)
            })
            setSelectedDishes(_dishes)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "category",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setCategories(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dish",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setDishes(response.data.data.items)
        setLoading(false);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/dish-price-type",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setPriceTypes(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    if (formData && formData.main_category && formData.main_category.id) {
      setFormData({
        ...formData,
        main_category: formData.main_category.id
      })
    }
  }, [formData.main_category])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('menucombo_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="main_category" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"main_category"}/>
                      <label htmlFor="main_category"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('category')}</label>
                      <span className="p-inputgroup">
                      <Dropdown options={categories}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"main_category"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    main_category: e.value
                                  })
                                }}
                                value={formData.main_category}
                                showClear
                      />
                      <Button type="button" className={"p-button"}
                              onClick={() => {
                                window.App.setState({sidebarCategoryForm: true})
                              }}>
                        <i className={"pi pi-window-maximize"}></i>
                      </Button>
                      <Sidebar position="right" visible={window.App.state.sidebarCategoryForm} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => window.App.setState({
                                 sidebarCategoryForm: false,
                               })}>
                        <_NR_CategoryFormComponent/>
                      </Sidebar>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="dishes" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="dishes"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('dishes')}</label>
                      <span className="p-d-block">
                        <MultiSelect options={dishes}
                                     optionLabel="name"
                                     optionValue="id"
                                     emptyMessage={t('empty_message')}
                                     onChange={(e) => {
                                       setSelectedDishes(e.value)
                                       setFormData({
                                         ...formData,
                                         dishes: e.value
                                       })
                                     }}
                                     id={"dishes"}
                                     value={selectedDishes}
                                     className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="third_party_id" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"third_party_id"}/>
                      <label htmlFor="third_party_id"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('third_party_id')}</label>
                      <span className="p-d-block">
                      <InputText id="third_party_id" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     third_party_id: e.target.value
                                   })
                                 }}
                                 value={formData.third_party_id}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12"}>
                  <label>{t('prices')}</label>
                  <div className={"p-mt-2"}>
                    {priceTypes.map((type, index) => {
                      let _menucombo_prices = []
                      if (formData && formData.menucombo_prices) {
                        _menucombo_prices = formData.menucombo_prices
                      }
                      return (
                        <div className={"p-grid"}>
                          <div className={"p-col-4 p-col-align-center"}>
                            {type.value}
                          </div>
                          <div className={"p-col-4 p-col-align-center"}>
                            {formData && formData.menucombo_prices && formData.menucombo_prices[index] && formData.menucombo_prices[index].amount ? (
                              <InputText
                                onChange={(e) => {
                                  let _menucombo_price = {
                                    currency: "HUF",
                                    amount: Number(e.target.value),
                                    type: type.value
                                  }
                                  _menucombo_prices[index] = _menucombo_price
                                  setFormData({
                                    ...formData,
                                    _menucombo_prices
                                  })
                                }}
                                value={formData.menucombo_prices[index].amount}
                              />
                            ) : (
                              <InputText
                                onChange={(e) => {
                                  let _menucombo_price = {
                                    currency: "HUF",
                                    amount: Number(e.target.value),
                                    type: type.value
                                  }
                                  _menucombo_prices[index] = _menucombo_price
                                  setFormData({
                                    ...formData,
                                    _menucombo_prices
                                  })
                                }}
                              />
                            )}

                          </div>
                          <div className={"p-col-4 p-col-align-center"}>
                            HUF
                          </div>

                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_NR_MenucomboFormComponent);
