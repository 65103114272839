import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Password} from "primereact/password";
import axios from "axios";
import {Message} from "primereact/message";

const PassowrdresetSetPage = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const {t, i18n} = props;

  const validate = (data) => {
    let errors = {};
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "password-reset/finish",
      formData,
      {
        params: {},
        headers: {
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language'),
        }
      })
      .then(response => {
        setLoading(false)
        window.App.toastShow('success', t('success'), response.data.message)
        window.location.replace('/#/');
        window.App.forceUpdate()

      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
      })
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.getElementById('body').classList.add('outside')
    document.title = t('password_reset') + ' - ' + process.env.REACT_APP_TITLE;
    setFormData({
      ...formData,
      activateHash: window.App.urlVars().hash
    })
  }, [formData.activateHash]);

  return (
    <div className="PasswordresetPage p-component">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel>
        <div className={"p-grid p-mb-4"}>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-col-align-center"}>
            <h3 className={"p-m-0"}>
              <i className={"pi pi-user"}></i> {t('password_reset')}
            </h3>
          </div>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-text-right"}>

          </div>
        </div>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="new_password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="new_password"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('new_password')}</label>
                    <Password id="new_password"
                              tabIndex={3}
                              {...input}
                              toggleMask
                              autoFocus
                              feedback={false}
                              value={formData.new_password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  new_password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            {localStorage.getItem('instanceId') === null &&
            <Message severity={"warn"} className={"p-mb-3 w-100"} text={t('instance_not_found')}/>
            }
            {!formData.activateHash &&
            <Message severity={"warn"} className={"p-mb-3 w-100"} text={t('hash_not_found')}/>
            }
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                <Link to={"/"} className={"p-d-block"}>{t('login')}</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <Link to={"/registration"} className={"p-d-block"}>{t('registration')}</Link>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                {formData.new_password && formData.activateHash && localStorage.getItem('instanceId') !== null ? (
                  <Button type="submit"
                          label={t('password_reset')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled={submitting}/>
                ) : (
                  <Button type="submit"
                          label={t('password_reset')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled/>
                )}
              </div>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(PassowrdresetSetPage);
