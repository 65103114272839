import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch";
import {BlockUI} from "primereact/blockui";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";

const UsersFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderUserList: true,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderUserList: true,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (window.App.state.propsUser && window.App.state.propsUser.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/" + window.App.state.propsUser.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(localStorage.getItem('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/roles",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setLoading(false)
        let _roles = []
        let _userRoles = ''
        if (formData && formData.roles) {
          _userRoles = formData.roles.toString()
        }
        response.data.data.map((role, index) => {
          _roles.push(
            {
              code: [`${role}`][0],
              status: _userRoles.includes(role)
            }
          )
        })
        setRoles(_roles)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }, [formData.roles])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('user_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"email"}/>
                      <label htmlFor="email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('email')}</label>
                      <span className="p-d-block">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="contact_email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"contact_email"}/>
                      <label htmlFor="contact_email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_email')}</label>
                      <span className="p-d-block">
                      <InputText id="contact_email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_email: e.target.value
                                   })
                                 }}
                                 value={formData.contact_email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="contact_phone" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"contact_phone"}/>
                      <label htmlFor="contact_phone"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_phone')}</label>
                      <span className="p-d-block">
                      <InputText id="contact_phone" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_phone: e.target.value
                                   })
                                 }}
                                 value={formData.contact_phone}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="plain_password" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"plain_password"}/>
                      <label htmlFor="plain_password"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('new_password')}</label>
                      <span className="p-d-block">
                      <InputText id="plain_password" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     plain_password: e.target.value
                                   })
                                 }}
                                 value={formData.plain_password}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="repeat_password" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"repeat_password"}/>
                      <label htmlFor="repeat_password"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('password_check')}</label>
                      <span className="p-d-block">
                      <InputText id="repeat_password" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     repeat_password: e.target.value
                                   })
                                 }}
                                 value={formData.repeat_password}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </Panel>
            <>
              <Panel className={"p-mt-3"}>
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    <h2 className={"p-m-0"}>{t('roles')}</h2>
                  </div>
                  <div className={"p-col p-text-right p-col-align-center"}>

                  </div>
                </div>
                <div className={"p-grid p-mt-2"}>
                  {roles && roles[0] &&
                  <>
                    {roles.map(function (role, index) {
                      return (
                        <>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputSwitch
                              className={"p-mt-1"}
                              name={role.code}
                              value={role.code}
                              checked={role.status}
                              onChange={(e) => {
                                let _roles = roles
                                _roles[Number(index)].code = e.target.name
                                _roles[Number(index)].status = e.value
                                setRoles(_roles)
                                setTimeout(() => {
                                  let __roles = []
                                  roles.map((_role, _index) => {
                                    if (_role.status === true) {
                                      __roles.push(_role.code)
                                    }
                                  })
                                  setFormData({...formData, roles: __roles})
                                }, 200)
                              }}/>
                          </div>
                          <div className={"p-col-10  p-col-align-center"}>
                            {t(role.code).toLowerCase()}
                          </div>
                        </>
                      )
                    })}
                  </>
                  }
                </div>
              </Panel>
            </>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(UsersFormComponent);
