import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import HelpComponent from "../HelpComponent";

const _FM_UnitFormBulkComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({
    number_of_double_beds: 0,
    number_of_single_beds: 0,
    number_of_extra_beds: 0,
  });
  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/bulk-create",
      formData,
      {
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
      .then(response => {
        setFormData(response.data.data)
        setLoading(false)
        window.App.toastShow('success', t('success'), response.data.message)
        window.App.setState({
          rerenderUnitList: true,
          selectedUnitId: response.data.data.id
        })
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category?search[accommodation]=" + window.App.state.selectedAccommodation,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setCategory(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit?search[has_parent]=0&search[accommodation]=" + window.App.state.selectedAccommodation,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setUnit(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state.propsUnit && window.App.state.propsUnit.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + window.App.state.propsUnit.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + window.App.state.selectedCategoryId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setFormData({
          ...formData,
          residential_category: window.App.state.selectedCategoryId,
          building: response.data.data.accommodation.buildings[0].id
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.selectedCategoryId])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <h2 className={"p-m-0"}>{t('unit_form_bulk_create')}</h2>
            </div>
            <div className={"p-col p-text-right p-col-align-center"}>
              <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                      loading={loading}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="prefix" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"prefix"} />
                  <label htmlFor="prefix"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('prefix')}</label>
                  <span className="p-d-block">
                      <InputText id="prefix" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     prefix: e.target.value
                                   })
                                 }}
                                 value={formData.prefix}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="amount_of_residential_units" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"amount_of_residential_units"} />
                  <label htmlFor="amount_of_residential_units"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('amount_of_residential_units')}</label>
                  <span className="p-d-block">
                      <InputNumber id="amount_of_residential_units" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       amount_of_residential_units: e.value
                                     })
                                   }}
                                   value={formData.amount_of_residential_units}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="parent" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"parent"} />
                  <label htmlFor="parent"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('parent')}</label>
                  <span className="">
                      <Dropdown options={unit}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"parent"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    parent: e.value,
                                  })

                                }}
                                value={formData.parent}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="residential_category" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"residential_category"} />
                  <label htmlFor="residential_category"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('residential_category')}</label>
                  <span className="">
                      <Dropdown options={category}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"residential_category"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    residential_category: e.value
                                  })
                                }}
                                value={formData.residential_category}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="number_of_double_beds" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"number_of_double_beds"} />
                  <label htmlFor="number_of_double_beds"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_double_beds')}</label>
                  <span className="p-d-block">
                      <InputNumber id="number_of_double_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_double_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_double_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="number_of_single_beds" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"number_of_double_beds"} />
                  <label htmlFor="number_of_double_beds"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_single_beds')}</label>
                  <span className="p-d-block">
                      <InputNumber id="number_of_single_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_single_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_single_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="number_of_extra_beds" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <HelpComponent type={"field"} field={"number_of_extra_beds"} />
                  <label htmlFor="number_of_extra_beds"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_extra_beds')}</label>
                  <span className="p-d-block">
                      <InputNumber id="number_of_extra_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_extra_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_extra_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(_FM_UnitFormBulkComponent);
