import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";

import ProfileFormComponent from "../components/profile/ProfileFormComponent";

const ProfilePage = (props) => {

  const {t} = props;

  useEffect(() => {
    document.title = t('profile') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="ProfilePage p-component p-m-lg-4">
      <ProfileFormComponent/>
    </div>
  )
}
export default withTranslation('common')(ProfilePage);
