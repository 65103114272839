import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_MenuListComponent from "../components/_NR_menu/_NR_MenuListComponent";
import _NR_MenuFormComponent from "../components/_NR_menu/_NR_MenuFormComponent";

const _NR_MenuPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('menu') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_MenuPage p-component p-m-lg-4">
      <Panel>
        <_NR_MenuListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarMenuForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarMenuForm: false,
               })}>
        <_NR_MenuFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_NR_MenuPage);
