import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {addLocale} from "primereact/api";
import {InputSwitch} from "primereact/inputswitch";
import {BlockUI} from "primereact/blockui";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";

const _FM_ReservationFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [dailyReservationAllowed, setDailyReservationAllowed] = useState(false);
  const [dailyReservationOnly, setDailyReservationOnly] = useState(false);
  const [reservedFromDate, setReservedFromDate] = useState(moment.utc(new Date()).local().format(t('YYYY-MM-DD')));
  const [reservedFromTime, setReservedFromTime] = useState('15:00');
  const [reservedToDate, setReservedToDate] = useState(moment.utc(new Date()).add(1, 'd').local().format(t('YYYY-MM-DD')));
  const [reservedToTime, setReservedToTime] = useState('10:00');
  const [relocated, setRelocated] = useState(false);
  const [unit, setUnit] = useState([]);
  const [berth, setBerth] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const apiCall = (method, url, data) => {
    return axios({
      method: method,
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + url,
      data,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
  }

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    const _fromDatePart = moment.utc(reservedFromDate).local().format('YYYY-MM-DD');
    const _fromTimePart = reservedFromTime;
    const [_fromHour, _fromMinute] = _fromTimePart.split(":");
    const _fromAdjustedHour = (parseInt(_fromHour, 10) - 1).toString().padStart(2, "0");
    const _fromAdjustedTimePart = `${_fromAdjustedHour}:${_fromMinute}`;
    const _fromCombinedString = `${_fromDatePart}T${_fromAdjustedTimePart}:00Z`;
    const _fromUtcDate = new Date(_fromCombinedString);
    const _toDatePart = moment.utc(reservedToDate).local().format('YYYY-MM-DD');
    const _toTimePart = reservedToTime;
    const [_toHour, _toMinute] = _toTimePart.split(":");
    const _toAdjustedHour = (parseInt(_toHour, 10) - 1).toString().padStart(2, "0");
    const _toAdjustedTimePart = `${_toAdjustedHour}:${_toMinute}`;
    const _toCombinedString = `${_toDatePart}T${_toAdjustedTimePart}:00Z`;
    const _toUtcDate = new Date(_toCombinedString);
    data = {
      ...data,
      reserved_from: _fromUtcDate,
      reserved_to: _toUtcDate
    }
    if (formData && formData.id) {
      apiCall('patch', 'reservation/' + formData.id, data)
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderReservationList: true,
            selectedReservationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarReservationForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      apiCall('post', 'reservation', data)
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderReservationList: true,
            selectedReservationId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarReservationForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const DailyReservationAllowed = (unitId) => {
    apiCall('get', 'residential-unit/' + unitId)
      .then(response => {
        if (response.data && response.data.data && response.data.data.residential_category) {
          apiCall('get', "residential-category/" + response.data.data.residential_category.id)
            .then(response => {
              if (response.data && response.data.data && response.data.data.accommodation) {
                apiCall('get', "accommodation/" + response.data.data.accommodation.id)
                  .then(response => {
                    if (response.data && response.data.data && response.data.data.daily_reservation_allowed && response.data.data.daily_reservation_allowed === true) {
                      setDailyReservationAllowed(true)
                    } else {
                      setDailyReservationAllowed(false)
                    }
                  })
                  .catch(error => {
                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                  })
              }
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('default', {
    firstDayOfWeek: 1,
    dayNames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
    dayNamesShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    dayNamesMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    monthNames: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    monthNamesShort: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    today: t('today'),
    clear: t('delete')
  });

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (formData && formData.residential_unit && formData.residential_unit.id) {
      setFormData({
        ...formData,
        residential_unit: formData.residential_unit.id
      })
    }
    if (formData && formData.residential_unit && !formData.residential_unit.id) {
      apiCall('get', 'berth' + '?search[residential_unit]=' + formData.residential_unit)
        .then(response => {
          setLoading(false)
          setBerth(response.data.data.items);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [formData.residential_unit])

  useEffect(() => {
    if (formData && formData.berth && formData.berth.id) {
      setFormData({
        ...formData,
        berth: formData.berth.id
      })
    }
    if (formData && formData.berth && formData.berth.residential_unit) {
      setFormData({
        ...formData,
        residential_unit: formData.berth.residential_unit.id,
        berth: formData.berth.id
      })
    }
  }, [formData.berth])

  useEffect(() => {
    if (window.App.state && window.App.state.propsReservation && window.App.state.propsReservation.id) {
      setLoading(true)
      apiCall('get', 'reservation/' + window.App.state.propsReservation.id)
        .then(response => {
          setLoading(false)
          if (response.data && response.data.data && response.data.data.residential_unit && response.data.data.residential_unit.id) {
            DailyReservationAllowed(response.data.data.residential_unit.id)
          }
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(localStorage.getItem('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.propsReservation])

  useEffect(() => {
    //separate reservation date and times
    if (formData.reserved_from) {
      let dateString = formData.reserved_from.toString();
      let date = new Date(dateString);
      let formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
      let formattedTime = `${String(date.getUTCHours() + 1).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}`;
      setReservedFromDate(moment.utc(formattedDate).local().format(t('YYYY-MM-DD')))
      setReservedFromTime(formattedTime)
    }
  }, [formData.reserved_from])

  useEffect(() => {
    //separate reservation date and times
    if (formData.reserved_to) {
      let dateString = formData.reserved_to.toString()
      let date = new Date(dateString);
      let formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
      let formattedTime = `${String(date.getUTCHours() + 1).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}`;
      setReservedToDate(moment.utc(formattedDate).local().format(t('YYYY-MM-DD')))
      setReservedToTime(formattedTime)
    }
  }, [formData.reserved_to])

  useEffect(() => {
    let query = ''
    if (window.App.state.selectedAccommodation) {
      query = '?search[accommodation]=' + window.App.state.selectedAccommodation
    }
    apiCall('get', 'residential-unit' + query)
      .then(response => {
        setUnit(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col p-col-align-center"}>
                  <h2 className={"p-m-0 p-d-inline"}>
                    {t('reservation_form')}
                  </h2>
                  {formData.relocated_to &&
                  <>
                <span className={"p-badge p-badge-warning p-ml-2"}>{t('relocated_to')}<i
                  className="pi pi-sign-in p-ml-1" style={{fontSize: "0.6rem"}}></i></span>
                  </>
                  }
                  {formData.relocated_from &&
                  <>
                <span className={"p-badge p-badge-warning p-ml-2"}><i className="pi pi-sign-out p-mr-1"
                                                                      style={{fontSize: "0.6rem"}}></i>{t('relocated_from')}</span>
                  </>
                  }
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-xl-4"}>
                  <Field name="residential_unit" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"residential_unit"}/>
                      <label htmlFor="residential_unit"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('residential_unit')}</label>
                      <span className="">
                      <Dropdown options={unit}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"residential_unit"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  if (e.value !== formData.residential_unit) {
                                    setRelocated(true)
                                  }
                                  setFormData({
                                    ...formData,
                                    residential_unit: e.value
                                  })
                                  DailyReservationAllowed(e.value)
                                }}
                                value={formData.residential_unit}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4 p-xl-4"}>
                  <Field name="berth" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"berth"}/>
                      <label htmlFor="berth"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('berth')}</label>
                      <span className="">
                    {formData.residential_unit && berth && berth[0] ? (
                      <Dropdown options={berth}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"berth"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    berth: e.value
                                  })
                                }}
                                value={formData.berth}
                                showClear
                      />
                    ) : (
                      <Dropdown disabled/>
                    )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                  <Field name="daily_reservation_only" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"daily_reservation_only"}/>
                      <label htmlFor="daily_reservation_only"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('daily_reservation_only')}</label>
                      <span className="p-d-block">
                        {dailyReservationAllowed === true ? (
                          <InputSwitch
                            className={"p-mt-1"}
                            name={"daily_reservation_only"}
                            value={dailyReservationOnly}
                            checked={dailyReservationOnly}
                            onChange={(e) => {
                              setDailyReservationOnly(e.value)
                              if(!formData.id) {
                                setReservedToDate(reservedFromDate)
                                setReservedToTime(reservedFromTime)
                              }
                            }}/>
                        ) : (
                          <InputSwitch
                            className={"p-mt-1"}
                            disabled/>
                        )}
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"} style={{minWidth: '160px'}}>
                  <Field name="reserved_from" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"reserved_from"}/>
                      <label htmlFor="reserved_from"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('reserved_from')}</label>
                      <span className="">
                    {formData.residential_unit ? (
                      <>
                        {dailyReservationOnly === true ? (
                          <div className={"p-inputgroup"}>
                            <Calendar value={reservedFromDate}
                                      dateFormat={t('YY-MM-DD')}
                                      placeholder={reservedFromDate}
                                      locale="default"
                                      id={"reserved_from"}
                                      onChange={(e) => {
                                        setReservedFromDate(moment.utc(e.value).local().format(t('YYYY-MM-DD')))
                                      }}/>
                            <Calendar value={reservedFromTime}
                                      dateFormat={t('YY-MM-DD')}
                                      placeholder={reservedFromTime}
                                      locale="default"
                                      id={"reserved_from"}
                                      showIcon
                                      showTime={dailyReservationOnly}
                                      timeOnly={dailyReservationOnly}
                                      hourFormat="24"
                                      stepMinute={30}
                                      onChange={(e) => {
                                        setReservedFromTime(moment.utc(e.value).local().format('HH:mm'))
                                      }}/>
                          </div>
                        ) : (
                          <div className="p-inputgroup calendar-icon-only">
                            <InputMask id={"reserved_from"}
                                       mask={t('9999-99-99')}
                                       value={reservedFromDate}
                                       onChange={(e) => {
                                         if (e.value.indexOf("_") === -1) {
                                           if (e.value === '') {
                                             e.value = null
                                           }
                                           setReservedFromDate(e.value)
                                         }
                                       }}
                            />
                            <Calendar value={reservedFromDate}
                                      locale="default"
                                      dateFormat={t('YY-MM-DD')}
                                      showIcon
                                      onChange={(e) => {
                                        setReservedFromDate(moment.utc(e.value).local().format(t('YYYY-MM-DD')))
                                      }}/>
                          </div>
                        )}
                      </>
                    ) : (
                      <InputMask mask={t('9999-99-99')} disabled/>
                    )}
                        </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"} style={{minWidth: '160px'}}>
                  <Field name="reserved_to" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"reserved_to"}/>
                      <label htmlFor="reserved_to"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('reserved_to')}</label>
                      <span className="">
                    {formData.residential_unit ? (
                      <>
                        {dailyReservationOnly === true ? (
                          <div className={"p-inputgroup"}>
                            <Calendar value={reservedToDate}
                                      dateFormat={t('YY-MM-DD')}
                                      placeholder={reservedToDate}
                                      locale="default"
                                      id={"reserved_to"}
                                      onChange={(e) => {
                                        setReservedToDate(moment.utc(e.value).local().format(t('YYYY-MM-DD')))
                                      }}/>
                            <Calendar value={reservedToTime}
                                      placeholder={reservedToTime}
                                      locale="default"
                                      id={"reserved_to"}
                                      dateFormat={t('YY-MM-DD')}
                                      showIcon
                                      showTime={dailyReservationOnly}
                                      timeOnly={dailyReservationOnly}
                                      hourFormat="24"
                                      stepMinute={30}
                                      onChange={(e) => {
                                        setReservedToTime(moment.utc(e.value).local().format('HH:mm'))
                                      }}/>
                          </div>
                        ) : (
                          <div className="p-inputgroup calendar-icon-only">
                            <InputMask id={"reserved_to"}
                                       mask={t('9999-99-99')}
                                       value={reservedToDate}
                                       onChange={(e) => {
                                         if (e.value.indexOf("_") === -1) {
                                           if (e.value === '') {
                                             e.value = null
                                           }
                                           setReservedToDate(e.value)
                                         }
                                       }}
                            />
                            <Calendar value={reservedToDate}
                                      locale="default"
                                      dateFormat={t('YY-MM-DD')}
                                      showIcon
                                      onChange={(e) => {
                                        setReservedToDate(moment.utc(e.value).local().format(t('YYYY-MM-DD')))
                                      }}/>
                          </div>
                        )}
                      </>
                    ) : (
                      <InputMask mask={t('9999-99-99')} disabled/>
                    )}
                        </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="note" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"note"}/>
                      <label htmlFor="note"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('note')}</label>
                      <span className="p-d-block">
                      <InputText id="note" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     note: e.target.value
                                   })
                                 }}
                                 value={formData.note}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="status" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"status"}/>
                      <label htmlFor="status"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('status')}</label>
                      <span className="p-d-block">
                      <InputText id="status" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     status: e.target.value
                                   })
                                 }}
                                 value={formData.status}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              {formData && formData.id && !formData.relocated_to && relocated === true &&
              <div className={"p-grid p-mt-2"}>
                <div className={"p-col-12 p-text-right"}>
                  <Button type="button" className="p-button-success" icon={"pi pi-sign-in"}
                          loading={loading}
                          label={t('relocate')}
                          onClick={() => {
                            setLoading(true)
                            apiCall('post', 'reservation/' + formData.id + '/relocate', formData)
                              .then(response => {
                                setLoading(false)
                                window.App.toastShow('success', t('success'), response.data.message)
                                window.App.setState({
                                  rerenderReservationList: true,
                                })
                              })
                              .catch(error => {
                                setLoading(false)
                                window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                              })
                          }}/>
                </div>
              </div>
              }
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_FM_ReservationFormComponent);
