import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {withTranslation} from 'react-i18next';
import LangselectComponent from "./LangselectComponent";
import {SplitButton} from "primereact/splitbutton";
import {Dialog} from "primereact/dialog";
import HelpComponent from "./HelpComponent";

const NavbarComponent = (props) => {

  const {t, i18n} = props;
  const [menu, setMenu] = useState([]);
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    let _menu = [
      {
        label: t('control_panel'),
        icon: 'pi pi-sliders-v',
        url: '/#/control-panel',
        className: 'control-panel'
      }
    ]
    if (process.env.REACT_APP_BRAND === 'felhomatrac') {
      _menu.push(
        {
          label: t('calendar'),
          icon: 'pi pi-calendar',
          url: '/#/calendar',
          className: '_FM_calendar'
        },
        {
          label: t('reservations'),
          icon: 'pi pi-check-square',
          url: '/#/reservation',
          className: '_FM_reservation'
        },
        {
          label: t('master_data'),
          icon: 'pi pi-box',
          className: '_FM_master-data',
          items: [
            {
              label: t('services'),
              icon: 'pi pi-box',
              url: '/#/service',
              className: '_FM_master-data'
            },
            {
              label: t('partners'),
              icon: 'pi pi-user',
              url: '/#/partner',
              className: 'partner'
            },
            {
              label: t('builder'),
              icon: 'pi pi-sitemap',
              url: '/#/builder',
              className: '_FM_master-data highlighted'
            },
            {
              label: t('rescategories'),
              icon: 'pi pi-star',
              url: '/#/category',
              className: '_FM_master-data'
            },
            {
              label: t('units'),
              icon: 'pi pi-home',
              url: '/#/unit',
              className: '_FM_master-data'
            },
            {
              label: t('berths'),
              icon: 'pi pi-inbox',
              url: '/#/berth',
              className: '_FM_master-data'
            },
            {
              label: t('ooo_units'),
              icon: 'pi pi-inbox',
              url: '/#/ooounit',
              className: '_FM_master-data'
            },
            {
              label: t('ooo_berths'),
              icon: 'pi pi-inbox',
              url: '/#/oooberth',
              className: '_FM_master-data'
            },
            {
              label: t('payment_methods'),
              icon: 'pi pi-credit-card',
              url: '/#/payment-method',
              className: '_FM_master-data'
            },
            {
              label: t('reservation_chanels'),
              icon: 'pi pi-wifi',
              url: '/#/chanel',
              className: '_FM_master-data'
            },
          ]
        },
      )
    }
    if (process.env.REACT_APP_BRAND === 'netrendel') {
      _menu.push(
        {
          label: t('master_data'),
          icon: 'pi pi-box',
          className: '_NR_master-data',
          items: [
            {
              label: t('menus'),
              icon: 'pi pi-book',
              url: '/#/menu',
              className: '_NR_master-data'
            },
            {
              label: t('categories'),
              icon: 'pi pi-list',
              url: '/#/category',
              className: '_NR_master-data'
            },
            {
              label: t('dishes'),
              icon: 'pi pi-circle-fill',
              url: '/#/dish',
              className: '_NR_master-data'
            },
            {
              label: t('menucombos'),
              icon: 'pi pi-sitemap',
              url: '/#/menucombo',
              className: '_NR_master-data'
            },
            {
              label: t('orderspot'),
              icon: 'pi pi-qrcode',
              url: '/#/orderspot',
              className: '_NR_master-data'
            },
          ]
        },
      )
    }
    if (process.env.REACT_APP_BRAND === 'paycenter') {
      _menu.push(
        {
          label: t('master_data'),
          icon: 'pi pi-box',
          className: '_NR_master-data',
          items: [
            {
              label: t('subscriptions'),
              icon: 'pi pi-book',
              url: '/#/subscription',
              className: '_NR_master-data'
            },
          ]
        },
      )
    }
    if (localStorage.getItem('roles') && localStorage.getItem('roles').toString().includes('ROLE_SUPER_ADMIN')) {
      _menu.push(
        {
          label: t('admin'),
          icon: 'pi pi-cog',
          className: 'admin',
          items: [
            {
              label: t('help'),
              icon: 'pi pi-question-circle',
              url: '/#/help',
              className: '_NR_master-data'
            },
            {
              label: t('monolog'),
              icon: 'pi pi-list',
              url: '/#/monolog',
              className: '_NR_master-data'
            },
            {
              label: t('registrations'),
              icon: 'pi pi-users',
              url: '/#/registration',
              className: '_NR_master-data'
            },
          ]
        }
      )
    }
    setMenu(_menu)
    let _profile = [
      {
        label: t('profile'),
        icon: 'pi pi-user',
        command: () => {
          window.location.replace('/#/profile');
          window.App.forceUpdate()
        }
      },
      {
        label: t('users'),
        icon: 'pi pi-users',
        command: () => {
          window.location.replace('/#/user');
          window.App.forceUpdate()
        }
      },
      {
        label: t('operators'),
        icon: 'pi pi-briefcase',
        command: () => {
          window.location.replace('/#/operator');
          window.App.forceUpdate()
        }
      },
    ]
    if (process.env.REACT_APP_BRAND === 'felhomatrac') {
      _profile.push(
        {
          label: t('accommodations'),
          icon: 'pi pi-home',
          command: () => {
            window.location.replace('/#/accommodation');
            window.App.forceUpdate()
          }
        },
      )
    }
    if (process.env.REACT_APP_BRAND === 'netrendel') {
      _profile.push(
        {
          label: t('pointofsales'),
          icon: 'pi pi-home',
          command: () => {
            window.location.replace('/#/pointofsale');
            window.App.forceUpdate()
          }
        },
      )
    }
    if (process.env.REACT_APP_BRAND === 'paycenter') {
      _profile.push(
        {
          label: t('acceptingplaces'),
          icon: 'pi pi-home',
          command: () => {
            window.location.replace('/#/acceptplace');
            window.App.forceUpdate()
          }
        },
        {
          label: t('revolutsettings'),
          icon: 'pi pi-credit-card',
          command: () => {
            window.location.replace('/#/revolutsettings');
            window.App.forceUpdate()
          }
        },
      )
    }
    _profile.push(
      {
        label: t('logout'),
        icon: 'pi pi-sign-out',
        command: () => {
          if (window.confirm(t('confirm_logout'))) {
            window.App.logout()
          }
        }
      },
    )
    setProfile(_profile)
  }, [])

  return (
    <nav>
      <>
        <Menubar
          style={{minHeight: '52px'}}
          start={
            <>
              <Link to={"/"}>
                <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className={"p-ml-2 p-mt-2 p-mb-0"}/>
                <span className={"p-d-block p-mb-2 p-ml-2"}>{localStorage.getItem("instanceName")}</span>
              </Link>
            </>
          }
          model={menu}
          className={"p-py-0"}
          end={
            <>
              <HelpComponent type={"global"} position={"UNDER_HELP_BUTTON"}/>
              <SplitButton label={t('profile')}
                           id="buttonProfile"
                           onClick={() => {
                             document.getElementById('buttonProfile').getElementsByClassName('p-splitbutton-menubutton')[0].click()
                           }}
                           icon="pi pi-user"
                           className={"p-button-success p-ml-2"}
                           model={profile}/>
              <LangselectComponent/>
            </>
          }
        />
      </>
      <HelpComponent type={"global"} position={"TOP_OF_THE_PAGE"}/>
    </nav>
  )
}
export default withTranslation('common')(NavbarComponent);
