import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_OooberthListComponent from "../components/_FM_oooberth/_FM_OooberthListComponent";
import _FM_OooberthFormComponent from "../components/_FM_oooberth/_FM_OooberthFormComponent";

const _FM_OooberthPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('oooberth') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_OooberthPage p-component p-m-lg-4">
      <Panel>
        <_FM_OooberthListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOooberthForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOooberthForm: false,
               })}>
        <_FM_OooberthFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_OooberthPage);
