import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import {InputText} from "primereact/inputtext";

import HelpComponent from "../HelpComponent";

const _NR_PointofsaleListComponent = (props) => {

  const {t} = props;
  const [pointofsale, setPointofsale] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let query = window.App.transformQueryParams(lazyParams)
      window.App.setState({rerenderPointofsaleList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale" + query,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setTotalRecords(response.data.data.total_item_count);
          setPointofsale(response.data.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', pointofsaleSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', pointofsaleSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarPointofsaleForm: true,
                    propsPointofsale: {id: rowData.id}
                  })
                }}/>
        {rowData && rowData.deleted_at_snapshot ? (
          <Button icon="pi pi-undo" className="p-button-sm p-button-success"
                  tooltip="Visszaállítás"
                  onClick={() => {
                    if (window.confirm('A törlés visszaállításának megerősítésére van szükség!')) {
                      axios({
                        method: 'get',
                        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale/" + rowData.id + "/restore",
                        params: {},
                        headers: {
                          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                          "Sys-name": localStorage.getItem('instanceId'),
                          "Preferred-locale": localStorage.getItem('language')
                        }
                      })
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres visszaállítás!',);
                          loadLazyData();
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }
                  }}
          />
          ):(
          <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                  tooltip="Törlés"
                  onClick={() => {
                    if (window.confirm('A törlés megerősítésére van szükség!')) {
                      axios({
                        method: 'delete',
                        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale/" + rowData.id,
                        params: {},
                        headers: {
                          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                          "Sys-name": localStorage.getItem('instanceId'),
                          "Preferred-locale": localStorage.getItem('language')
                        }
                      })
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres törlés!',);
                          loadLazyData();
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }
                  }}
          />
          )}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPointofsaleList === true])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col"}>
          <h2 className={"p-m-0"}>{t('pointofsale_list')}</h2>
        </div>
        <div className={"p-col p-text-right p-col-align-center"}>
          <HelpComponent type={"site"} position={"UNDER_HELP_BUTTON"}/>
          {lazyParams && lazyParams.is_active === 0 ? (
            <Button type="button" icon={"pi pi-eye-slash"}
                    onClick={() => {
                      let _lazyParams = lazyParams
                      delete lazyParams.is_active
                      setLazyParams(_lazyParams)
                      loadLazyData()
                    }}
                    className="p-button-danger p-ml-2"/>
          ) : (
            <Button type="button" icon={"pi pi-eye"}
                    onClick={() => {
                      setLazyParams({
                        ...lazyParams,
                        is_active: 0
                      })
                    }}
                    className="p-button-success p-ml-2"/>
          )}
          <span className="p-input-icon-left p-ml-2">
              <i className="pi pi-search"/>
              <InputText id="search"
                         placeholder={t('search')}
                         onChange={(e) => {
                           clearTimeout(window.searchTimeout)
                           window.searchTimeout = setTimeout(function () {
                             setLazyParams({
                               ...lazyParams,
                               search: e.target.value
                             })
                           }, 1000)
                         }}
                         className={""}
              />
            </span>
          <Button type="button"
                  label={t('new_pointofsale')}
                  icon={"pi pi-plus"}
                  onClick={() => {
                    window.App.setState({
                      propsPointofsale: null,
                      sidebarPointofsaleForm: true
                    })
                  }}
                  className="p-button-success p-ml-2"/>
        </div>
      </div>
      <HelpComponent type={"site"} position={"TOP_OF_THE_PAGE"}/>
      <DataTable
        ref={dt}
        size={"small"}
        emptyMessage={t('empty_message')}
        value={pointofsale} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage={t('empty_message')}
        autoLayout={true}
        filterDisplay="menu"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
        selectionMode={"single"}
        onRowDoubleClick={(rowData) => {
          window.App.setState({
            sidebarPointofsaleForm: true,
            propsPointofsale: {id: rowData.data.id}
          })
        }}
      >
        <Column field="id" sortable></Column>
        <Column field="name" sortable header={t('name')}></Column>
        <Column field="short_name" sortable header={t('short_name')}></Column>
        <Column field="zip_code" sortable header={t('zip_code')}></Column>
        <Column field="city" sortable header={t('city')}></Column>
        <Column field="street_and_number" sortable header={t('street_and_number')}></Column>
        <Column field="contact_name" sortable header={t('contact_name')}></Column>
        <Column field="contact_email" sortable header={t('contact_email')}></Column>
        <Column field="contact_phone" sortable header={t('contact_phone')}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap p-text-right"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(_NR_PointofsaleListComponent);
