import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Tree} from 'primereact/tree';
import axios from "axios";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import _FM_CategoryFormComponent from "../components/_FM_category/_FM_CategoryFormComponent";
import {Sidebar} from "primereact/sidebar";
import _FM_UnitFormComponent from "../components/_FM_unit/_FM_UnitFormComponent";
import _FM_BerthFormComponent from "../components/_FM_berth/_FM_BerthFormComponent";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {OverlayPanel} from "primereact/overlaypanel";
import _FM_UnitFormBulkComponent from "../components/_FM_unit/_FM_UnitFormBulkComponent";
import _FM_BerthFormBulkComponent from "../components/_FM_berth/_FM_BerthFormBulkComponent";

const _FM_BuilderPage = (props) => {

  const {t, i18n} = props;
  const [loading, setLoading] = useState(false);
  const [accommodations, setAccommodations] = useState([])
  const [ntakKategoria, setNtakKategoria] = useState([])
  const [nodes, setNodes] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [beds, setBeds] = useState([]);
  const [tmp, setTmp] = useState('');
  const opb = useRef(null);
  const opu = useRef(null);

  const apiCall = (method, url, data) => {
    return axios({
      method: method,
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + url,
      data,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
  }

  useEffect(() => {
    document.title = t('builder') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
    apiCall('get', 'accommodation')
      .then(response => {
        setAccommodations(response.data.data.items);
        window.App.setState({
          selectedAccommodation: response.data.data.items[0].id
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    apiCall('get', 'dictionary/ntak/kategoria')
      .then(response => {
        setNtakKategoria(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  useEffect(() => {
    if (window.App.state.selectedAccommodation) {
      apiCall('get', "residential-category?search[accommodation]=" + window.App.state.selectedAccommodation)
        .then(response => {
          let _cats = []
          response.data.data.items.map((cat, index) => {
            _cats.push({
              key: 'C-' + cat.id,
              originalId: cat.id,
              originalData: cat,
              parentId: null,
              type: 'category',
              level: 1,
              label: cat.name,
              expanded: true,
              draggable: false,
              selectable: false,
            })
          })
          setCategories(_cats)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      apiCall('get', 'residential-unit/tree')
        .then(response => {
          let _units = []
          response.data.map((unit, index) => {
            _units.push({
              key: 'U-' + unit.id,
              originalId: unit.id,
              originalData: unit,
              parentId: unit.residential_category.id,
              type: 'unit',
              level: 2,
              label: unit.name,
              expanded: true,
              draggable: true,
              selectable: false,
              children: unit.children
            })
          })
          setUnits(_units)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      apiCall('get', 'berth')
        .then(response => {
          let _beds = []
          response.data.data.items.map((bed, index) => {
            _beds.push({
              key: 'B-' + bed.id,
              originalId: bed.id,
              originalData: bed,
              parentId: bed.residential_unit.id,
              type: 'bed',
              level: 3,
              label: bed.name,
              draggable: true,
            })
          })
          setBeds(_beds)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.setState({
      rerenderCategoryList: false,
      rerenderUnitList: false,
      rerenderBerthList: false,
    })
  }, [
    window.App.state.selectedAccommodation,
    window.App.state.rerenderCategoryList === true,
    window.App.state.rerenderUnitList === true,
    window.App.state.rerenderBerthList === true,
  ]);

  useEffect(() => {
    let _nodes = []
    categories.map((category, index) => {
      let _units = units.filter(item => item.parentId === category.originalId)
      let __units = []
      _units.map((unit, index) => {
        let _beds = beds.filter(item => item.parentId === unit.originalId)
        let _children = []
        if (unit.children && unit.children[0]) {
          unit.children.map((children, index) => {
            let __beds = []
            if (children.berths && children.berths[0]) {
              children.berths.map((bed, index) => {
                __beds.push({
                  key: 'BB-' + bed.id,
                  originalId: bed.id,
                  originalData: bed,
                  parentId: children.originalId,
                  type: 'bed',
                  level: 4,
                  label: bed.name,
                  draggable: true,
                })
              })
            }
            _children.push({
              key: 'UU-' + children.id,
              originalId: children.id,
              originalData: children,
              parentId: unit.originalId,
              type: 'unit',
              level: 3,
              label: children.name,
              expanded: true,
              draggable: true,
              selectable: false,
              children: __beds
            })
          })
          _beds = [
            ..._beds,
            ..._children
          ]
        }
        __units.push({
          ...unit,
          children: _beds
        })
      })
      _nodes.push({
        ...category,
        children: __units
      })
    })
    setNodes(_nodes)
  }, [categories, units, beds]);

  return (
    <div className="_FM_BuilderPage p-component p-m-lg-4">
      <Panel>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            {window.App.state.selectedAccommodation &&
            <Button type="button" label={t('new_category')} icon={"pi pi-plus"}
                    onClick={() => {
                      window.App.setState({
                        selectedAccommodationId: window.App.state.selectedAccommodation,
                        propsCategory: null,
                        sidebarCategoryForm: true
                      })
                    }}
                    className="p-button-success p-mr-2"/>
            }
            {selectedIds && selectedIds[0] &&
            <Button type="button" label={t('delete_selected')} icon={"pi pi-trash"}
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        apiCall('delete', 'berth/bulk-delete', selectedIds)
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres törlés!',);
                            window.App.setState({
                              rerenderBerthList: true,
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}
                    className="p-button-danger p-mr-2"/>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>
            {nodes && !nodes[0] &&
            <>Válassz száláshelyet: &nbsp;</>
            }
            <Dropdown options={accommodations}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      id={"accommodation"}
                      emptyMessage={t('empty_message')}
                      onChange={(e) => {
                        window.App.setState({selectedAccommodation: e.value})
                      }}
                      value={window.App.state.selectedAccommodation}
            />
          </div>
        </div>
        {nodes && nodes[0] &&
        <>
          <Tree value={nodes}
                header={() => {
                  return (
                    <>
                      <div className={"category"}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2"}>

                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('name')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('ntak_kategoria')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_double_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_single_beds')}
                          </div>
                          <div className={"p-col-2"}>

                          </div>
                        </div>
                      </div>
                      <div className={"unit"}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2"}>

                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('name')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_double_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_single_beds')}
                          </div>
                          <div className={"p-col-2 p-text-center"}>
                            {t('number_of_extra_beds')}
                          </div>
                          <div className={"p-col-2"}>

                          </div>
                        </div>
                      </div>
                    </>
                  )
                }}
                metaKeySelection={true}
                selectionMode="multiple"
                selectionKeys={selectedKeys}
                onSelectionChange={(e, node) => {
                  let _selectedIds = []
                  let values = Object.keys(e.value)
                  if (values && values[0]) {
                    values.map((value, index) => {
                      var selectedId = beds.filter(item => item.key === value)
                      _selectedIds.push(selectedId[0].originalId)
                    })
                  }
                  setSelectedIds(_selectedIds)
                  setSelectedKeys(e.value)
                }}
                dragdropScope="0"
                onDragDrop={(e) => {
                  if (e.dragNode.type && e.dragNode.type === 'unit') {
                    if (e.dropNode.type && e.dropNode.type === 'category') {
                      apiCall('patch', 'residential-unit/' + e.dragNode.originalId, {
                        residential_category: e.dropNode.originalId,
                        parent: null
                      })
                        .then(response => {
                          window.App.toastShow('success', t('success'), response.data.message)
                          window.App.setState({
                            rerenderUnitList: true,
                          })
                        })
                        .catch(error => {
                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                        })
                    } else if (e.dropNode.type && e.dropNode.type === 'unit' && e.dropNode.level < 3) {
                      apiCall('patch', 'residential-unit/' + e.dragNode.originalId, {
                        parent: e.dropNode.originalId

                      })
                        .then(response => {
                          window.App.toastShow('success', t('success'), response.data.message)
                          window.App.setState({
                            rerenderUnitList: true,
                          })
                        })
                        .catch(error => {
                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                        })
                    }
                  } else if (e.dragNode.type && e.dragNode.type === 'bed') {
                    apiCall('patch', 'berth/' + e.dragNode.originalId, {
                      residential_unit: e.dropNode.originalId
                    })
                      .then(response => {
                        window.App.toastShow('success', t('success'), response.data.message)
                        window.App.setState({
                          rerenderBerthList: true,
                        })
                      })
                      .catch(error => {
                        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                      })
                  }
                }}
                togglerTemplate={() => {
                  return (
                    false
                  )
                }}
                nodeTemplate={(node, options) => {
                  if (node.type === 'category') {
                    return (
                      <div className={node.type}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            {t('residential_category')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "name"}
                                       disabled={loading}
                                       onClick={(e) => setTmp(e.target.value)}
                                       onBlur={(e) => {
                                         if (e.target.value && e.target.value !== tmp) {
                                           setLoading(true)
                                           apiCall('patch', 'residential-category/' + node.originalId, {
                                             name: e.target.value
                                           })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderUnitList: true,
                                               })
                                               setLoading(false)
                                             })
                                             .catch(error => {
                                               setLoading(false)
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                             })
                                         }
                                       }}
                                       onChange={(e) => {
                                         node.label = e.target.value
                                         window.App.forceUpdate()
                                       }}
                                       value={node.label}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <Dropdown options={ntakKategoria}
                                      disabled={loading}
                                      optionLabel="key"
                                      optionValue="value"
                                      filter
                                      id={node.originalId + "-ntak_kategoria"}
                                      emptyMessage={t('empty_message')}
                                      onChange={(e) => {
                                        setLoading(true)
                                        apiCall('patch', 'residential-category/' + node.originalId, {
                                          ntak_kategoria: e.value
                                        })
                                          .then(response => {
                                            window.App.toastShow('success', t('success'), response.data.message)
                                            window.App.setState({
                                              rerenderUnitList: true,
                                            })
                                            setLoading(false)
                                          })
                                          .catch(error => {
                                            setLoading(false)
                                            window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                          })
                                      }}
                                      value={node.originalData.ntak_kategoria}
                                      className={"w-100"}
                            />
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_double_beds"}
                                         locale="hu-HU"
                                         disabled={loading}
                                         onClick={(e) => setTmp(e.target.value)}
                                         onBlur={(e) => {
                                           if (e.target.value && e.target.value !== tmp) {
                                             setLoading(true)
                                             apiCall('patch', 'residential-category/' + node.originalId, {
                                               number_of_double_beds: e.target.value
                                             })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderUnitList: true,
                                                 })
                                                 setLoading(false)
                                               })
                                               .catch(error => {
                                                 setLoading()
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         value={node.originalData.number_of_double_beds}
                                         onChange={(e) => {
                                           node.originalData.number_of_double_beds = e.value
                                           window.App.forceUpdate()
                                         }}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_single_beds"}
                                         disabled={loading}
                                         locale="hu-HU"
                                         onClick={(e) => setTmp(e.target.value)}
                                         onBlur={(e) => {
                                           if (e.target.value && e.target.value !== tmp) {
                                             setLoading(true)
                                             apiCall('patch', 'residential-category/' + node.originalId, {
                                               number_of_single_beds: e.target.value
                                             })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderUnitList: true,
                                                 })
                                                 setLoading(false)
                                               })
                                               .catch(error => {
                                                 setLoading(false)
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               })
                                           }
                                         }}
                                         value={node.originalData.number_of_single_beds}
                                         onChange={(e) => {
                                           node.originalData.number_of_single_beds = e.value
                                           window.App.forceUpdate()
                                         }}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button type="button" icon="pi pi-bolt" tooltip={t('bulk_actions')}
                                    onClick={(e) => {
                                      window.App.setState({
                                        selectedCategoryId: node.originalId,
                                      })
                                      opu.current.toggle(e)
                                    }} small
                                    className="p-button-success p-ml-2"/>
                            <OverlayPanel ref={opu}>
                              <_FM_UnitFormBulkComponent/>
                            </OverlayPanel>
                            <Button type="button" icon={"pi pi-plus"}
                                    onClick={() => {
                                      window.App.setState({
                                        selectedCategoryId: node.originalId,
                                        propsUnit: null,
                                        sidebarUnitForm: true
                                      })
                                    }}
                                    tooltip={t('new_unit')}
                                    small
                                    className="p-button-success p-ml-2"/>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        apiCall('delete', 'residential-category/' + node.originalId)
                                          .then(response => {
                                            window.App.setState({rerenderCategoryList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if (node.type === 'unit') {
                    return (
                      <div className={node.type + " unit-level-" + node.level}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            <i className={"pi pi-arrows-v p-mr-2 p-ml-5"}></i>
                            {t('residential_unit')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "-name"}
                                       onClick={(e) => setTmp(e.target.value)}
                                       disabled={loading}
                                       onBlur={(e) => {
                                         if (e.target.value && e.target.value !== tmp) {
                                           setLoading(true)
                                           apiCall('patch', 'residential-unit/' + node.originalId, {
                                             name: e.target.value
                                           })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderUnitList: true,
                                               })
                                               setLoading(false)
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               setLoading(false)
                                             })
                                         }
                                       }}
                                       value={node.label}
                                       onChange={(e) => {
                                         node.label = e.target.value
                                         window.App.forceUpdate()
                                       }}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_double_beds"}
                                         locale="hu-HU"
                                         disabled={loading}
                                         onClick={(e) => setTmp(e.target.value)}
                                         onBlur={(e) => {
                                           if (e.target.value && e.target.value !== tmp) {
                                             setLoading(true)
                                             apiCall('patch', 'residential-unit/' + node.originalId, {
                                               number_of_double_beds: e.target.value
                                             })
                                               .then(response => {
                                                 setLoading(false)
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderUnitList: true,
                                                 })
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                                 setLoading(false)
                                               })
                                           }
                                         }}
                                         value={node.originalData.number_of_double_beds}
                                         onChange={(e) => {
                                           node.originalData.number_of_double_beds = e.value
                                           window.App.forceUpdate()
                                         }}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_single_beds"}
                                         locale="hu-HU"
                                         disabeld={loading}
                                         onClick={(e) => setTmp(e.target.value)}
                                         onBlur={(e) => {
                                           if (e.target.value && e.target.value !== tmp) {
                                             setLoading(true)
                                             apiCall('patch', 'residential-unit/' + node.originalId, {
                                               number_of_single_beds: e.target.value
                                             })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderUnitList: true,
                                                 })
                                                 setLoading(false)
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                                 setLoading(false)
                                               })
                                           }
                                         }}
                                         value={node.originalData.number_of_single_beds}
                                         onChange={(e) => {
                                           node.originalData.number_of_single_beds = e.value
                                           window.App.forceUpdate()
                                         }}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputNumber id={node.originalId + "-number_of_extra_beds"}
                                         disabled={loading}
                                         locale="hu-HU"
                                         onClick={(e) => setTmp(e.target.value)}
                                         onBlur={(e) => {
                                           if (e.target.value && e.target.value !== tmp) {
                                             setLoading(true)
                                             apiCall('patch', 'residential-unit/' + node.originalId, {
                                               number_of_extra_beds: e.target.value
                                             })
                                               .then(response => {
                                                 window.App.toastShow('success', t('success'), response.data.message)
                                                 window.App.setState({
                                                   rerenderUnitList: true,
                                                 })
                                                 setLoading(false)
                                               })
                                               .catch(error => {
                                                 window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                                 setLoading(false)
                                               })
                                           }
                                         }}
                                         value={node.originalData.number_of_extra_beds}
                                         onChange={(e) => {
                                           node.originalData.number_of_extra_beds = e.value
                                           window.App.forceUpdate()
                                         }}
                                         className={"w-100"}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button type="button" icon="pi pi-bolt" tooltip={t('bulk_actions')}
                                    onClick={(e) => {
                                      window.App.setState({
                                        selectedUnitId: node.originalId,
                                      })
                                      opb.current.toggle(e)
                                    }} small
                                    className="p-button-success p-ml-2"/>
                            <OverlayPanel ref={opb}>
                              <_FM_BerthFormBulkComponent/>
                            </OverlayPanel>
                            <Button type="button" icon={"pi pi-plus"}
                                    tooltip={t('new_berth')}
                                    onClick={() => {
                                      window.App.setState({
                                        selectedUnitId: node.originalId,
                                        propsBerth: null,
                                        sidebarBerthForm: true
                                      })
                                    }}
                                    small
                                    className="p-button-success p-ml-2"/>
                            <Button type="button" icon={"pi pi-list"}
                                    tooltip={t('new_unit')}
                                    onClick={() => {
                                      window.App.setState({
                                        selectedCategoryId: node.originalData.residential_category.id,
                                        selectedUnitId: node.originalId,
                                        propsBerth: null,
                                        sidebarUnitForm: true
                                      })
                                    }}
                                    small
                                    className="p-button-success p-ml-2"/>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        apiCall('delete', 'residential-unit/' + node.originalId)
                                          .then(response => {
                                            window.App.setState({rerenderUnitList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if (node.type === 'bed') {
                    return (
                      <div className={node.type + " bed-level-" + node.level}>
                        <div className={"p-grid"}>
                          <div className={"p-col-2 p-col-align-center"}>
                            <span className={" p-ml-5"}></span><i className={"pi pi-arrows-v p-mr-2 p-ml-5"}></i>
                            {t('berth')}
                          </div>
                          <div className={"p-col-2 p-col-align-center"}>
                            <InputText id={node.originalId + "-name"}
                                       disabled={loading}
                                       onClick={(e) => setTmp(e.target.value)}
                                       onBlur={(e) => {
                                         if (e.target.value && e.target.value !== tmp) {
                                           setLoading(true)
                                           apiCall('patch', 'berth/' + node.originalId, {
                                             name: e.target.value
                                           })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               window.App.setState({
                                                 rerenderUnitList: true,
                                               })
                                               setLoading(false)
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                               setLoading(false)
                                             })
                                         }
                                       }}
                                       value={node.label}
                                       onChange={(e) => {
                                         node.label = e.target.value
                                         window.App.forceUpdate()
                                       }}
                                       className={"w-100"}/>
                          </div>
                          <div className={"p-col-6"}>

                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button icon="pi pi-trash" className="p-button-danger p-ml-2"
                                    tooltip="Törlés"
                                    small
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        apiCall('delete', 'berth/' + node.originalId)
                                          .then(response => {
                                            window.App.setState({rerenderBerthList: true})
                                            window.App.toastShow('success', 'Sikeres törlés!',);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                }}
          />
        </>
        }
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarCategoryForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarCategoryForm: false,
               })}>
        <_FM_CategoryFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarUnitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarUnitForm: false,
               })}>
        <_FM_UnitFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarBerthForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarBerthForm: false,
               })}>
        <_FM_BerthFormComponent/>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_BuilderPage);
