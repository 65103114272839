import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _PC_RevolutsettingsListComponent from "../components/_PC_revolutsettings/_PC_RevolutsettingsListComponent";
import _PC_RevolutsettingsFormComponent from "../components/_PC_revolutsettings/_PC_RevolutsettingsFormComponent";
import _PC_RevolutsettingsShowComponent from "../components/_PC_revolutsettings/_PC_RevolutsettingsShowComponent";

const _PC_RevolutsettingsPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('revolutsettings') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_PC_RevolutsettingsPage p-component p-m-lg-4">
      <Panel>
        <_PC_RevolutsettingsListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarRevolutsettingsForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarRevolutsettingsForm: false,
               })}>
        <_PC_RevolutsettingsFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarRevolutsettingsShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarRevolutsettingsShow: false,
               })}>
        <_PC_RevolutsettingsShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_PC_RevolutsettingsPage);
