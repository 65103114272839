import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {BlockUI} from "primereact/blockui";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";
import {InputSwitch} from "primereact/inputswitch";
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";
import OperatorFormComponent from "../operator/OperatorFormComponent";
import _PC_RevolutsettingsFormComponent from "../_PC_revolutsettings/_PC_RevolutsettingsFormComponent";

const _PC_AcceptingplaceFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [operators, setOperators] = useState([]);
  const [revolutSettings, setRevolutSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accepting-place/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderAcceptingplaceList: true,
            selectedAcceptingplaceId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarAcceptingplaceForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accepting-place",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderAcceptingplaceList: true,
            selectedAcceptingplaceId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarAcceptingplaceForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    setFormData({
      ...formData,
      operator_data: window.App.state.selectedOperatorId
    })
  }, [window.App.state.selectedOperatorId])

  useEffect(() => {
    if (formData && formData.operator_data && formData.operator_data.id) {
      setFormData({
        ...formData,
        operator_data: formData.operator_data.id
      })
    }
  }, [formData.operator_data])

  useEffect(() => {
    setFormData({
      ...formData,
      revolut_settings: window.App.state.selectedRevolutsettingsId
    })
  }, [window.App.state.selectedRevolutsettingsId])

  useEffect(() => {
    if (formData && formData.revolut_settings && formData.revolut_settings.id) {
      setFormData({
        ...formData,
        revolut_settings: formData.revolut_settings.id
      })
    }
  }, [formData.revolut_settings])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setOperators(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "revolut-settings",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setRevolutSettings(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state.propsAcceptingplace && window.App.state.propsAcceptingplace.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accepting-place/" + window.App.state.propsAcceptingplace.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(localStorage.getItem('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.selectedRevolutsettingsId])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('acceptingplace_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-9 p-xl-9"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-3 p-xl-3"}>
                  <Field name="revolut_integration" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"revolut_integration"}/>
                      <label htmlFor="revolut_integration"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('revolut_integration')}</label>
                      <span className="p-d-block">
                      <InputSwitch
                        className={"p-mt-1"}
                        name={"revolut_integration"}
                        value={formData.revolut_integration}
                        checked={formData.revolut_integration}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            revolut_integration: e.value
                          })
                        }}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="operator_data" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"operator_data"}/>
                      <label htmlFor="operator_data"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('operator_data')}</label>
                      <span className="p-inputgroup">
                      <Dropdown options={operators}
                                optionLabel="company_name"
                                optionValue="id"
                                id={"operator_data"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    operator_data: e.value
                                  })
                                }}
                                value={formData.operator_data}
                                showClear
                      />
                      <Button type="button" className={"p-button"}
                              onClick={() => {
                                window.App.setState({sidebarOperatorForm: true})
                              }}>
                        <i className={"pi pi-window-maximize"}></i>
                      </Button>
                      <Sidebar position="right" visible={window.App.state.sidebarOperatorForm} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => window.App.setState({
                                 sidebarOperatorForm: false,
                               })}>
                        <OperatorFormComponent/>
                      </Sidebar>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="revolut_settings" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"revolut_settings"}/>
                      <label htmlFor="operator_data"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('revolutsetting')}</label>
                      <span className="p-inputgroup">
                        {window.App.state.propsAcceptingplace && window.App.state.propsAcceptingplace.id ? (
                          <Dropdown options={revolutSettings}
                                    optionLabel="name"
                                    optionValue="id"
                                    id={"revolut_settings"}
                                    emptyMessage={t('empty_message')}
                                    value={formData.revolut_settings}
                                    disabled
                          />
                        ):(
                          <Dropdown options={revolutSettings}
                                    optionLabel="name"
                                    optionValue="id"
                                    id={"revolut_settings"}
                                    emptyMessage={t('empty_message')}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        revolut_settings: e.value
                                      })
                                    }}
                                    value={formData.revolut_settings}
                                    showClear
                          />
                        )}
                        {!window.App.state.propsAcceptingplace &&
                        <>
                          <Button type="button" className={"p-button"}
                                  onClick={() => {
                                    window.App.setState({sidebarRevolutsettingsForm: true})
                                  }}>
                            <i className={"pi pi-window-maximize"}></i>
                          </Button>
                          <Sidebar position="right" visible={window.App.state.sidebarRevolutsettingsForm} closeOnEscape={true}
                                   style={{width: '780px'}}
                                   onHide={() => window.App.setState({
                                     sidebarRevolutsettingsForm: false,
                                   })}>
                            <_PC_RevolutsettingsFormComponent/>
                          </Sidebar>
                        </>
                        }
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_PC_AcceptingplaceFormComponent);
