import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";

import FormLocker from "../../util/FormLocker";

const HelpFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);
  const [types, setTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const [levels, setLevels] = useState([]);
  const [positions, setPositions] = useState([]);
  const [messageHu, setMessageHu] = useState('');
  const [messageEn, setMessageEn] = useState('');


  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    data = {
      ...data,
      "message-en": messageEn,
      "message-hu": messageHu,
    }
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/help-badge/" + formData.id,
        data,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          window.App.forceUpdate()
          window.App.help()
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderHelpList: true,
            selectedHelpId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarHelpForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/help-badge",
        data,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            "Sys-name": localStorage.getItem('instanceId'),
            "Preferred-locale": localStorage.getItem('language')
          }
        })
        .then(response => {
          window.App.forceUpdate()
          window.App.help()
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderHelpList: true,
            selectedHelpId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarHelpForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const Fields = (url) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/help-badge-form-field?url=" + url,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        let _fields = []
        if (response.data && response.data.data) {
          let _field = response.data.data[url.replace('/', '')]
          if (_field !== undefined) {
            response.data.data[url.replace('/', '')].map((field, index) => {
              _fields.push({
                name: field,
                key: field
              })
            })
          }
        }
        setFields(_fields);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (formData && formData.message) {
      setMessageEn(formData.message)
    }
  }, [formData.message])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/help-badge-type",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setTypes(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/help-badge-position",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setPositions(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/help-badge-level",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        "Sys-name": localStorage.getItem('instanceId'),
        "Preferred-locale": localStorage.getItem('language')
      }
    })
      .then(response => {
        setLevels(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state.propsHelp && window.App.state.propsHelp.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/help-badge/" + window.App.state.propsHelp.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.translated_values && response.data.data.translated_values.en) {
            setMessageEn(response.data.data.translated_values.en.message)
          }
          if (response.data && response.data.data && response.data.data.translated_values && response.data.data.translated_values.hu) {
            setMessageHu(response.data.data.translated_values.hu.message)
          }
          if (response.data.data.url) {
            Fields(response.data.data.url)
          }
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(localStorage.getItem('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('help_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <Field name="messageHu" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="messageHu"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('messageHu')}</label>
                      <span className="p-d-block">
                      <InputText id="message" {...input}
                                 onChange={(e) => {
                                   setMessageHu(e.target.value)
                                 }}
                                 value={messageHu}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <Field name="messageEn" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="message"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('messageEn')}</label>
                      <span className="p-d-block">
                      <InputText id="messageEn" {...input}
                                 onChange={(e) => {
                                   setMessageEn(e.target.value)
                                 }}
                                 value={messageEn}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <Field name="type" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="type"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('type')}</label>
                      <span className="p-d-block">
                      <Dropdown options={types}
                                filter
                                optionLabel="key"
                                optionValue="value"
                                id={"type"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    type: e.value
                                  })
                                }}
                                value={formData.type}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {(formData.type === 'ON_FIELD' || formData.type === 'ON_SITE') &&
                <div className={"p-col"}>
                  <Field name="url" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="url"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('url')}</label>
                      <span className="p-d-block">
                      <InputText id="url" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     url: e.target.value
                                   })
                                 }}
                                 onBlur={(e) => {
                                   Fields(e.target.value)
                                 }}
                                 value={formData.url}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                {formData.type === 'ON_FIELD' &&
                <div className={"p-col"}>
                  <Field name="form_field" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="form_field"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('form_field')}</label>
                      <span className="p-d-block">
                    <Dropdown options={fields}
                              optionLabel="name"
                              optionValue="key"
                              filter
                              id={"form_field"}
                              emptyMessage={t('empty_message')}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  form_field: e.value
                                })
                              }}
                              value={formData.form_field}
                              showClear
                    />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
              </div>
              <div className={"p-grid"}>
                {formData.type !== 'ON_FIELD' &&
                  <div className={"p-col"}>
                    <Field name="position" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="position"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>{t('position')}</label>
                        <span className="p-d-block">
                      <Dropdown options={positions}
                                optionLabel="key"
                                optionValue="value"
                                filter
                                id={"position"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    position: e.value
                                  })
                                }}
                                value={formData.position}
                                showClear
                      />
                      </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                }
                <div className={"p-col"}>
                  <Field name="level" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="level"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('level')}</label>
                      <span className="p-d-block">
                    <Dropdown options={levels}
                              optionLabel="key"
                              optionValue="value"
                              filter
                              id={"level"}
                              emptyMessage={t('empty_message')}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  level: e.value
                                })
                              }}
                              value={formData.level}
                              showClear
                    />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(HelpFormComponent);
