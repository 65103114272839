import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {withTranslation} from 'react-i18next';
import {Dialog} from "primereact/dialog";
import {Message} from "primereact/message";

const HelpComponent = (props) => {

  const {t, i18n} = props;
  const [dialogHelp, setDialogHelp] = useState(false);
  const [fieldMessage, setFieldMessage] = useState('');
  const [qGlobalMessages, setQGlobalMessages] = useState([]);
  const [sGlobalMessages, setSGlobalMessages] = useState([]);

  useEffect(() => {
    if (props.field && window.App.state.help) {
      let message = window.App.state.help[props.type].filter(item => item.field === props.field)
      if (message && message[0] && message[0].message) {
        setFieldMessage(message[0].message)
      }
    }
    if (props.type === 'global' && window.App.state.help && window.App.state.help.global && window.App.state.help.global[0]) {
      let message = window.App.state.help[props.type].filter(item => (item.position === 'UNDER_HELP_BUTTON' || item.position === null))
      if (message && message[0]) {
        setQGlobalMessages(message)
      }
    } else {
      setQGlobalMessages([])
    }
    if (props.type === 'site' && window.App.state.help && window.App.state.help.site && window.App.state.help.site[0]) {
      let message = window.App.state.help[props.type].filter(item => (item.position === 'UNDER_HELP_BUTTON' || item.position === null))
      if (message && message[0]) {
        setSGlobalMessages(message)
      }
    } else {
      setSGlobalMessages([])
    }
  }, [window.App.state.help])


  return (
    <>
      {window.App.state.help &&
      <>

        {props.type === 'global' && props.position === 'UNDER_HELP_BUTTON' &&
        <>
          {qGlobalMessages && qGlobalMessages[0] &&
          <Button type="button" className="p-button-warning" icon={"pi pi-question-circle"}
                  onClick={() => {
                    setDialogHelp(true)
                  }}/>
          }
          <Dialog header={t('help')} visible={dialogHelp} onHide={() => {
            setDialogHelp(false)
          }}>
            {window.App.state.help.global.map((help, index) => {
              if (help.position === 'UNDER_HELP_BUTTON' || help.position === null) {
                if (help.level == null) {
                  return (
                    <Message severity="" className={"p-d-block p-my-1 p-border-secondary"} text={help.message}/>
                  )
                } else if (help.level == 'LEVEL_SECONDARY') {
                  return (
                    <Message severity="secondary" className={"p-d-block p-my-1 p-bg-secondary"} text={help.message}/>
                  )
                } else {
                  let _severity = ''
                  if (help.level == 'LEVEL_WARNING') {
                    _severity = 'warn'
                  } else if (help.level == 'LEVEL_DANGER') {
                    _severity = 'error'
                  } else {
                    _severity = help.level.replace('LEVEL_', '')
                  }
                  return (
                    <Message severity={_severity.toString().toLowerCase()} className={"p-d-block p-my-1"}
                             text={help.message}/>
                  )
                }
              }
            })}
          </Dialog>
        </>
        }

        {props.type === 'global' && props.position === 'TOP_OF_THE_PAGE' &&
        <>
          {window.App.state.help.global.map((help, index) => {
            if (help.position === 'TOP_OF_THE_PAGE') {
              if (help.level === null) {
                return (
                  <Message severity="" className={"p-d-block p-m-1 p-border-secondary"} text={help.message}/>
                )
              } else if (help.level == 'LEVEL_SECONDARY') {
                return (
                  <Message severity="secondary" className={"p-d-block p-m-1 p-bg-secondary"} text={help.message}/>
                )
              } else {
                let _severity = ''
                if (help.level == 'LEVEL_WARNING') {
                  _severity = 'warn'
                } else if (help.level == 'LEVEL_DANGER') {
                  _severity = 'error'
                } else {
                  _severity = help.level.replace('LEVEL_', '')
                }
                return (
                  <Message severity={_severity.toString().toLowerCase()} className={"p-d-block p-m-1"}
                           text={help.message}/>
                )
              }
            }
          })}
        </>
        }

        {props.type === 'site' && props.position === 'TOP_OF_THE_PAGE' &&
        <>
          {window.App.state.help.site.map((help, index) => {
            if (help.position === 'TOP_OF_THE_PAGE') {
              if (help.level === null) {
                return (
                  <Message severity="" className={"p-d-block p-my-1 p-border-secondary"} text={help.message}/>
                )
              } else if (help.level == 'LEVEL_SECONDARY') {
                return (
                  <Message severity="secondary" className={"p-d-block p-my-1 p-bg-secondary"} text={help.message}/>
                )
              } else {
                let _severity = ''
                if (help.level == 'LEVEL_WARNING') {
                  _severity = 'warn'
                } else if (help.level == 'LEVEL_DANGER') {
                  _severity = 'error'
                } else {
                  _severity = help.level.replace('LEVEL_', '')
                }
                return (
                  <Message severity={_severity.toString().toLowerCase()} className={"p-d-block p-my-1"}
                           text={help.message}/>
                )
              }
            }
          })}
        </>
        }

        {props.type === 'site' && props.position === 'UNDER_HELP_BUTTON' &&
        <>
          {sGlobalMessages && sGlobalMessages[0] &&
          <Button type="button" className="p-button-warning" icon={"pi pi-question-circle"}
                  onClick={() => {
                    setDialogHelp(true)
                  }}/>
          }
          <Dialog header={t('help')} visible={dialogHelp} onHide={() => {
            setDialogHelp(false)
          }}>
            {window.App.state.help.site.map((help, index) => {
              if (help.position === 'UNDER_HELP_BUTTON' || help.position === null) {
                if (help.level === null) {
                  return (
                    <Message severity="" className={"p-d-block p-my-1 p-border-secondary"} text={help.message}/>
                  )
                } else if (help.level == 'LEVEL_SECONDARY') {
                  return (
                    <Message severity="secondary" className={"p-d-block p-my-1 p-bg-secondary"} text={help.message}/>
                  )
                } else {
                  let _severity = ''
                  if (help.level == 'LEVEL_WARNING') {
                    _severity = 'warn'
                  } else if (help.level == 'LEVEL_DANGER') {
                    _severity = 'error'
                  } else {
                    _severity = help.level.replace('LEVEL_', '')
                  }
                  return (
                    <Message severity={_severity.toString().toLowerCase()} className={"p-d-block p-my-1"}
                             text={help.message}/>
                  )
                }
              }
            })}
          </Dialog>
        </>
        }

        {props.type === 'site' && props.position === 'IN_ACTIVE_BOX' &&
        <div className={"p-mb-5"}>
          {window.App.state.help.site.map((help, index) => {
            if (help.position === 'IN_ACTIVE_BOX') {
              if (help.level === null) {
                return (
                  <Message severity="" className={"p-d-block p-my-1 p-border-secondary"} text={help.message}/>
                )
              } else if (help.level == 'LEVEL_SECONDARY') {
                return (
                  <Message severity="secondary" className={"p-d-block p-my-1 p-bg-secondary"} text={help.message}/>
                )
              } else {
                let _severity = ''
                if (help.level == 'LEVEL_WARNING') {
                  _severity = 'warn'
                } else if (help.level == 'LEVEL_DANGER') {
                  _severity = 'error'
                } else {
                  _severity = help.level.replace('LEVEL_', '')
                }
                return (
                  <Message severity={_severity.toString().toLowerCase()} className={"p-d-block p-my-1"}
                           text={help.message}/>
                )
              }
            }
          })}
        </div>
        }

        {fieldMessage &&
        <>
          <i className={"pi pi-question-circle p-mr-2"} onClick={() => {
            setDialogHelp(true)
          }}></i>
          <Dialog header={t('help')} visible={dialogHelp} onHide={() => {
            setDialogHelp(false)
          }}>
            <p>
              {fieldMessage}
            </p>
          </Dialog>
        </>
        }


      </>
      }


      {/*{window.App.state.help && window.App.state.help.site && window.App.state.help.site[0] &&*/}
      {/*<>*/}
      {/*  {window.App.state.help.site.map((help, index) => {*/}
      {/*    //console.log(help)*/}
      {/*    //console.log(props)*/}
      {/*    if(props.type === 'site' && help.position === 'TOP_OF_THE_PAGE') {*/}
      {/*      return(*/}
      {/*        <>{help.message}</>*/}
      {/*      )*/}
      {/*    }*/}
      {/*    if(props.type === 'site' && help.position === 'UNDER_HELP_BUTTON') {*/}
      {/*      return(*/}
      {/*        <>{help.message}</>*/}
      {/*      )*/}
      {/*    }*/}

      {/*  })}*/}
      {/*</>*/}
      {/*}*/}

    </>
  )
}
export default withTranslation('common')(HelpComponent);
