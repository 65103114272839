import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_ServiceListComponent from "../components/_FM_service/_FM_ServiceListComponent";
import _FM_ServiceFormComponent from "../components/_FM_service/_FM_ServiceFormComponent";

const _FM_ServicePage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('service') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_ServicePage p-component p-m-lg-4">
      <Panel>
        <_FM_ServiceListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarServiceForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarServiceForm: false,
               })}>
        <_FM_ServiceFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_ServicePage);
