import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";

import MonologListComponent from "../components/monolog/MonologListComponent";

const MonologPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('monolog') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="MonologPage p-component p-m-lg-4">
      <div className={"p-p-3 p-p-lg-0"}>
        <Panel>
          <MonologListComponent />
        </Panel>
      </div>
    </div>
  )
}
export default withTranslation('common')(MonologPage);
