import { useEffect, useRef } from 'react';

function FormLocker(callback, delay) {
  const intervalId = useRef(null);
  useEffect(() => {
    // Komponens mountolásakor elindítjuk az intervalt
    intervalId.current = setInterval(callback, delay);
    return () => {
      // Unmount esetén töröljük az intervalt
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [callback, delay]);
}

export default FormLocker;
